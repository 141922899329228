




































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import StepMenu from '../components/step-menu.vue'
import ContactInformation from '../components/contact-information.vue'
import BasicInformation from '../components/basic-information.vue'
import TokenInformation from '../components/token-information.vue'
import Description from '../components/description.vue'
import ContractMetric from '../components/contract-metric.vue'
import VestingSchedule from '../components/vesting-schedule.vue'
import RegistrationNotice from '../components/registration-notice.vue'

@Observer
@Component({
  components: {
    StepMenu,
    ContactInformation,
    BasicInformation,
    TokenInformation,
    Description,
    ContractMetric,
    VestingSchedule,
    RegistrationNotice,
  },
})
export default class NewProject extends Vue {
  activeStep = 1
  changeActiveStep(value: number): void {
    this.activeStep = value
  }
}
