


































































































import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {},
})
export default class VestingSchedule extends Vue {}
