























import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {},
})
export default class StepMenu extends Vue {
  // @Prop({ default: 1 }) activeStep!: number

  activeStep = 1
  toggleTab(step: number): void {
    this.activeStep = step
    this.$emit('onTabClick', step)
  }
  steps = [
    { title: 'Step 1', text: 'Basic Information' },
    { title: 'Step 2', text: 'Token Information' },
    { title: 'Step 3', text: 'Description' },
    { title: 'Step 4', text: 'Contract Metrics' },
    { title: 'Step 5', text: 'Vesting Schedule' },
    { title: 'Step 6', text: 'Registration notice' },
    { title: 'Step 7', text: 'Decisions and Next Steps' },
  ]
}
