import { render, staticRenderFns } from "./contact-information.vue?vue&type=template&id=6fad05f0&scoped=true&"
import script from "./contact-information.vue?vue&type=script&lang=ts&"
export * from "./contact-information.vue?vue&type=script&lang=ts&"
import style0 from "./contact-information.vue?vue&type=style&index=0&id=6fad05f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fad05f0",
  null
  
)

export default component.exports