import { render, staticRenderFns } from "./token-information.vue?vue&type=template&id=47564b52&scoped=true&"
import script from "./token-information.vue?vue&type=script&lang=ts&"
export * from "./token-information.vue?vue&type=script&lang=ts&"
import style0 from "./token-information.vue?vue&type=style&index=0&id=47564b52&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47564b52",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
